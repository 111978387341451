import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Account } from 'src/app/models/Account';
import { ChatMessage } from 'src/app/models/ChatMessage';
import { Transaction } from 'src/app/models/Transaction';
import { PilotService } from 'src/app/providers/pilot-service/pilot-service';

@Component({
  selector: 'pilot-message',
  templateUrl: './pilot-message.component.html',
  styleUrls: ['./pilot-message.component.scss'],
  host: {
    '[class.human-message]': 'message?.type === "human"',
    '[class.bot-message]': 'message?.type === "ai"',
    '[class.pending]': 'message?.state === "pending"',
    '[class.is-streaming]': 'message?.state === "streaming"',
    '[class.is-done]': 'message?.state !== "streaming"',
    '[class.is-error]': 'message?.state === "error"',
    '[class.is-cancelled]': 'message?.state === "aborted"',
  }
})
export class PilotMessageComponent  implements OnInit {

  _message: ChatMessage;
  @Input() set message(message: ChatMessage) {
    this._message = message;
  }
  get message() {
    return this._message;
  }

  constructor(private toastController: ToastController, private pilotService: PilotService) { }

  ngOnInit() {}


  async copyMessage(){
    navigator.clipboard.writeText(this._message.text);
    await this.toastController.create({
      message: 'Nachricht in Zwischenablage kopiert.',
      duration: 2000,
      position: "top"
    }).then((toast) => {
      toast.present();
    });
  }

  async deleteMessage(){
    // @ts-ignore
    this.onDelete.emit(this._message);
    await this.toastController.create({
      message: 'Nachricht wurde gelöscht.',
      duration: 2000,
      position: "top"
    }).then((toast) => {
      toast.present();
    });
  }

  async cancelMessage(){
    // @ts-ignore
    this.onCancel.emit(this._message);
    await this.toastController.create({
      message: 'Nachricht wurde abgebrochen.',
      duration: 2000,
      position: "top"
    }).then((toast) => {
      toast.present();
    });
  }

  async retryMessage(){
    // @ts-ignore
    this.onRetry.emit(this._message);
    await this.toastController.create({
      message: 'Nachricht wird erneut gesendet.',
      duration: 2000,
      position: "top"
    }).then((toast) => {
      toast.present();
    });
  }


}
