import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Webhook } from 'src/app/models/Webhook';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';
import { WebhookService } from 'src/app/providers/webhook-service/webhook-service';

@Component({
  selector: 'webhook-list',
  templateUrl: './webhook-list.component.html',
  styleUrls: ['./webhook-list.component.scss'],
})
export class WebhookListComponent  implements OnInit {


  _webhooks: Webhook[] = [];
  @Input()
  set webhooks(webhooks: Webhook[]) {
    this._webhooks = webhooks;
  }
  get webhooks(): Webhook[] {
    return this._webhooks;
  }

  @Output() itemChanged = new EventEmitter<any>();

  editName: boolean = false;
  newName: string = '';
  
  isEdited: boolean = false;
  testDataModalOpen: boolean = false;
  testWebhook: Webhook;

  accountOptions: any = [];

  constructor(public projectService: ProjectService, 
    private webhookService: WebhookService,
    public institutionService: InstitutionService,
    private toastController: ToastController,
    private translate: TranslationBridge) { }


    ngOnInit() {
    
      /*
      this._webhooks = [
        {
          "name": "Meine n8n Test Integration Marcel",
          "active": true,
          "key": "testkey1testkey1testkey1testkey1testkey1",
          "createdAt": new Date(),
          "expiresAt": new Date(),
          "lastUsedAt": new Date(),
          "scopes": ['ALL', 'ACCOUNTS', 'TRANSACTIONS'],
          "accounts": [],
          "allowedIPs": [],
        },
      ];*/
  
      
      this.institutionService.getAccountsAsOptions(this.projectService.currentProject.id).then((options) => {
        let emptyOption = {
            value: '',
            title: this.translate.get('ACCOUNT_SELECT_ALL'),
            subtitle: this.translate.get('ACCOUNT_SELECT_ALL_INFO'),
            clearOthers: true
          };
        this.accountOptions = [emptyOption, ...options];
      });
  
    }
  
    /*
      Form functions
    */
  
    public enterEditName(ev: any, index: number){
      ev.stopPropagation();
      ev.preventDefault();
      this.newName = this._webhooks[index].name;
      this.editName = true;
    }
    public acceptEditName(ev: any, index: number){
      ev.stopPropagation();
      ev.preventDefault();
      this._webhooks[index].name = this.newName;
      this.editName = false;
      this.isEdited = true;
    }
    public cancelEditName(ev: any, index: number){
      ev.stopPropagation();
      ev.preventDefault();
      this.editName = false;
    }
    
    public async toggleActiveStatus(index: number, ev: any){
      
      if(!this.checkInput(index)){
        this._webhooks[index].active = false;
        return false;
      }

      this._webhooks[index].active = !this._webhooks[index].active;
      this.isEdited = true;
      
      if(this._webhooks[index].active){
        await this.webhookService.activateWebhook(this._webhooks[index].id);
      } else {
        await this.webhookService.deactivateWebhook(this._webhooks[index].id);
      } 
      this.toastController.create({
        message: (this._webhooks[index].active ? 'Webhook activated.' : 'Webhook deactivated.'),
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }
  
    public setScope(index: number, ev: any){
      this._webhooks[index].scope = ev.value;
      this.isEdited = true;
    }

    public setAccounts(index: number, ev: any){
      const selected = ev.map(e => e.value);
      this._webhooks[index].accounts = selected;
      this.isEdited = true;
    }
  
    public saveWebhook(index: number, ev: any){
  
      if(ev){
        ev.stopPropagation();
        ev.preventDefault();
      }
      const hookObj = this._webhooks[index];
  

      if(!hookObj.id){
        this.webhookService.addWebhook(hookObj).then((res: Webhook) => {
            this.isEdited = false;
            this.toastController.create({
              message: 'Webhook created!',
              duration: 2000,
              position: 'top'
            }).then((toast) => {
              toast.present();
            });
        }).catch((err) => {
          this.toastController.create({
            message: 'Couldn\'t create Webhook. Please try again.',
            duration: 2000,
            position: 'top'
          }).then((toast) => {
            toast.present();
          });
        });
      } else {
        this.webhookService.updateWebhook(hookObj).then((res: Webhook) => {
          this.isEdited = false;
  
          this.toastController.create({
            message: 'Webook updated!',
            duration: 2000,
            position: 'top'
          }).then((toast) => {
            toast.present();
          });
        }).catch((err) => {
          this.toastController.create({
            message: 'Couldn\'t update Webhook. Please try again.',
            duration: 2000,
            position: 'top'
          }).then((toast) => {
            toast.present();
          });
        });
      }
  
  
    }
  
    public deleteWebhook(index: number, ev: any){
      ev.stopPropagation();
      ev.preventDefault();
      const hookObj = this._webhooks[index];
      this.webhookService.deleteWebhook(hookObj.id).then((res) => {
        this.isEdited = false;
        setTimeout(() => {
          this.itemChanged.emit();
        },300);
        this.toastController.create({
          message: 'Webhook deleted!',
          duration: 2000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
      }).catch((err) => {
        this.toastController.create({
          message: 'Couldn\'t delete Webhook. Please try again.',
          duration: 2000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
      });
    }

    /*
      Header Editor
    */
    addHeader(index: number){
      if(!this._webhooks[index].headers) this._webhooks[index].headers = [];
      this._webhooks[index].headers.push({
        key: '',
        value: ''
      });
      this.markEdited();
    }
    removeHeader(index: number, headerIndex: number){
      if(!this._webhooks[index].headers) return;
      this._webhooks[index].headers.splice(headerIndex, 1);
      this.markEdited();
    }

    markEdited(){
      this.isEdited = true;
    }
  
    checkInput(index: number){
      if(!this._webhooks[index].url || this._webhooks[index].url.indexOf('https://') == -1) {
        this.toastController.create({
          message: 'Please provide a valid URL first. Webhook URL must start with https://.',
          duration: 2000,
          cssClass: 'toast-error',
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
        return false;
      }
      return true;
    }

    webhookMessages(index: number){
      var messages = [];
      var key = this._webhooks[index];
      if(!key.active){
        messages.push({
          type: "warning",
          icon: "power",
          title: "Webhook is inactive",
          text: this.translate.get('WEBHOOK_OFFLINE_INFO'),
          actionTitle: "",
          action: null
        });
      }
      return messages;
    }
  
  
    public getAccountName(id: number){
      let account = this.accountOptions.find((a) => a.value == id);
      if(account) return account.title;
      return id.toString();
    }
    public getAccountIban(id: number){
      let account = this.accountOptions.find((a) => a.value == id);
      if(account) return account.subtitle;
      return id.toString();
    }

    public openTestDataModal(index: number){
      this.testDataModalOpen = true;
      this.testWebhook = this._webhooks[index];
    }
    public openTestDataModalDidClose(){
      this.testDataModalOpen = false;
      this.testWebhook = null;
    }

}
