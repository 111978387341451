import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { AuthService } from './providers/auth-service/auth-service';
import { NotificationService } from './providers/notification-service/notification-service';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { ShortcutService } from './providers/shortcut-service/shortcut-service';

const SENSITIVE_OPTIONS = ['balance', 'iban', 'all'];

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  showEmailVerifiedBanner: boolean = true;

  hideSensitive: string[] = [];
  sensitiveClass: string = '';

  constructor(
    private platform: Platform,
    private notificationService: NotificationService,
    private shortcutService: ShortcutService,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      
      // localization
      moment.locale('de');
      this.translate.setDefaultLang('de');
      
      // browser language
      /*if (this.translate.getBrowserLang() !== undefined) {
        this.translate.use(this.translate.getBrowserLang());
        moment.locale(this.translate.getBrowserLang());
      }*/

      // user customization
      this.auth.isReady().then(() => {
        let userPreferencedMode = this.auth.getOption('colorMode');
        let systemDarkmode = window.matchMedia('(prefers-color-scheme: dark)');
        systemDarkmode.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

        // we stick to light theme per default
        //if(systemDarkmode.matches) this.toggleDarkTheme(systemDarkmode.matches);
        if(userPreferencedMode == "dark") this.toggleDarkTheme(true);
        if(userPreferencedMode == "light") this.toggleDarkTheme(false);

      });


    });
  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  toggleSensitive() {
    // go through all sensitive options step by step
    let index = SENSITIVE_OPTIONS.indexOf(this.hideSensitive[0]);
    index++;
    if(index >= SENSITIVE_OPTIONS.length) index = -1;
    if(index == -1) this.hideSensitive = [];
      else this.hideSensitive = [SENSITIVE_OPTIONS[index]];
    
    this.updateSensitiveClass();
    
  }

  hideAllIban() {
    let index = this.hideSensitive.indexOf('iban');
    if(index == -1){
      this.hideSensitive.push('iban');
      this.updateSensitiveClass();
    }
  }
  showAllIban() {
    let index = this.hideSensitive.indexOf('iban');
    if(index != -1){
      this.hideSensitive.splice(index, 1);
      this.updateSensitiveClass();
    }
  }

  hideAllBalances() {
    let index = this.hideSensitive.indexOf('balance');
    if(index == -1){
      this.hideSensitive.push('balance');
      this.updateSensitiveClass();
    }
  }
  showAllBalances() {
    let index = this.hideSensitive.indexOf('balance');
    if(index != -1){
      this.hideSensitive.splice(index, 1);
      this.updateSensitiveClass();
    }
  }

  hideAllSensitive() {
    this.hideSensitive = ['all'];
    this.updateSensitiveClass();
  }

  getCurrentSensitiveFilter() {
    return this.hideSensitive;
  }
  isActiveSensitiveFilter(filter: string) {
    return this.hideSensitive.indexOf(filter) != -1;
  }

  updateSensitiveClass(){
    let newClass = '';
    this.hideSensitive.forEach((option) => {
      newClass += 'hide-' + option + ' ';
    });
    if(this.hideSensitive.indexOf('all') != -1) newClass = 'hide-all';
    if(newClass == '') newClass = 'hide-none';
    if(this.sensitiveClass != newClass){
      this.sensitiveClass = newClass;
      
      SENSITIVE_OPTIONS.forEach((option) => {
       if(document.getElementsByTagName('ion-app')[0]) document.getElementsByTagName('ion-app')[0].classList.remove('hide-'+option);
      });

      if(newClass != 'hide-none' && document.getElementsByTagName('ion-app')[0]){
        let classes = newClass.split(' ');
        classes.forEach((classString) => {
          if(classString.trim()) document.getElementsByTagName('ion-app')[0].classList.add(classString.trim());
        });
      }
    }

  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
  }
}
