import { Account } from "./Account";
import { Transaction } from "./Transaction";

export class ChatMessage {
    id: string;
    userId: string;
    type: 'user' | 'ai';
    state: 'pending' | 'streaming' | 'done' | 'error' | 'aborted';
    text: string;
    createdAt: Date;
    data: {
        transactions?: Transaction[];
        accounts?: Account[];
    }
}
