import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Integration } from 'src/app/models/Integration';
import { IntegrationService } from 'src/app/providers/integration-service/integration-service';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { ToastController } from '@ionic/angular';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';
import { Webhook } from 'src/app/models/Webhook';
import { WebhookService } from 'src/app/providers/webhook-service/webhook-service';


@Component({
  selector: 'integration-test-data-modal',
  templateUrl: './integration-test-data-modal.component.html',
  styleUrls: ['./integration-test-data-modal.component.scss'],
})
export class IntegrationTestDataModalComponent implements OnInit {

  loading: boolean = false;

  @Input() isOpen: boolean = false;
  @Input() mode: "webhook" | "integration" = "integration";
  @Input() integration: Integration;
  @Input() webhook: Webhook;

  @Output() onClose = new EventEmitter<boolean>();

  transactions: any[] = [];

  accountOptions: any[] = [];

  autogeneratedFields = [];
  // [0] = { amount: false, purpose: true, type: false, counterpartName: false, counterpartIban: false, counterpartBic: false }

  constructor(
    public integrationService: IntegrationService,
    public institutionService: InstitutionService,
    public webhookService: WebhookService,
    private projectService: ProjectService,
    private toastController: ToastController,
    private translation: TranslationBridge
    ) { }

  ngOnInit() {
    this.getAccounts();
  }

  closeModal(){
    this.isOpen = false;
    this.onClose.emit(this.isOpen);
  }
  
  getAccounts(){
    this.loading = true;
    let project = this.projectService.getCurrentProject();
      this.institutionService.getAccountsAsOptions(project.id).then((accounts)=>{
        this.accountOptions = accounts;
        this.loading = false;
      });
  }

  addTransaction(){

    if(this.accountOptions.length <= 0){
      this.toastController.create({
        message: this.translation.get('TEST_DATA_MODAL_NEED_TO_ADD_ACCOUNT'),
        duration: 5000,
        position: 'top',
        cssClass: 'toast-error',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
              this.isOpen = false;
            }
          }
        ]
      }).then((toast)=>{
        toast.present();
      });
    } else {
      this.transactions.push({
        accountId: this.accountOptions[0].value,
        amount: 1.00,
        currency: "EUR",
        valueDate: new Date(),
        purpose: "",
        type: "Überweisung",
        counterpartName: "",
        counterpartIban: "",
        counterpartBic: "",
      });
    }
  }

  accountChange(ev,index){
    this.transactions[index].accountId = ev.value;
  }

  formatAmount(ev,index){
    let value = this.transactions[index].amount.toString();
    if(value){
      let roundedNumber = parseFloat(value).toFixed(2);
      let str = roundedNumber.toString();
      let parts = str.split('.');
      let integerPart = parts[0];
      let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
      value = integerPart + decimalPart;
      value = parseFloat(value);
      if(isNaN(value)) value = 0;
      console.log(value);
      this.transactions[index].amount = value;
    }
  }

  removeRow(index){
    this.transactions.splice(index, 1);
  }

  changeValueDate(ev,index){
    console.log(ev.detail.value);
    this.transactions[index].valueDate = new Date(ev.detail.value);
  }

  autogenerate(index){
    
    let generated = {
      accountId: this.accountOptions[0].value,
      amount: Math.floor(Math.random() * 195) + 5,
      currency: "EUR",
      valueDate: new Date(),
      purpose: this.generatePurpose(),
      type: "Überweisung",
      counterpartName: this.generateFakeName(),
      counterpartIban: this.generateFakeIBAN(),
      counterpartBic: this.generateFakeBIC(),
    };

    // only fill out empty fields
    let autogeneratedLog = this.autogeneratedFields[index] || {};
    if (!this.transactions[index].accountId || autogeneratedLog.accountId) {
      this.transactions[index].accountId = generated.accountId;
      autogeneratedLog.accountId = true;
    }
    if (this.transactions[index].amount == 1 || autogeneratedLog.amount) {
      this.transactions[index].amount = generated.amount;
      autogeneratedLog.amount = true;
    }
    if (!this.transactions[index].currency || autogeneratedLog.currency) {
      this.transactions[index].currency = generated.currency;
      autogeneratedLog.currency = true;
    }
    if (!this.transactions[index].valueDate || autogeneratedLog.valueDate) {
      this.transactions[index].valueDate = generated.valueDate;
      autogeneratedLog.valueDate = true;
    }
    if (!this.transactions[index].purpose || autogeneratedLog.purpose) {
      this.transactions[index].purpose = generated.purpose;
      autogeneratedLog.purpose = true;
    }
    if (!this.transactions[index].type || autogeneratedLog.type) {
      this.transactions[index].type = generated.type;
      autogeneratedLog.type = true;
    }
    if (!this.transactions[index].counterpartName || autogeneratedLog.counterpartName) {
      this.transactions[index].counterpartName = generated.counterpartName;
      autogeneratedLog.counterpartName = true;
    }
    if (!this.transactions[index].counterpartIban || autogeneratedLog.counterpartIban) {
      this.transactions[index].counterpartIban = generated.counterpartIban;
      autogeneratedLog.counterpartIban = true;
    }
    if (!this.transactions[index].counterpartBic || autogeneratedLog.counterpartBic) {
      this.transactions[index].counterpartBic = generated.counterpartBic;
      autogeneratedLog.counterpartBic = true;
    }

    this.autogeneratedFields[index] = autogeneratedLog;

    return true;
  }

  generateFakeName() {
    const preNames = [
      'Max',
      'Moritz',
      'Peter',
      'Paul',
      'Hans',
      'John',
      'Jane',
      'Mary',
      'Michael',
      'David',
      'Michaela'
    ];
    const lastNames = [
      'Mustermann',
      'Musterfrau',
      'Doe',
      'Smith',
      'Miller',
      'Müller',
      'Schmidt',
      'Schneider',
      'Fischer',
    ];
    return preNames[Math.floor(Math.random() * preNames.length)] + ' ' + lastNames[Math.floor(Math.random() * lastNames.length)];
  }  

  generateFakeBIC() {
    return "TESTDEBF" + Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  }
  

  generateFakeIBAN() {
    var countryCodes = {
      // Add more country codes and their corresponding lengths here
      // You can refer to the official IBAN registry for valid country codes and lengths
      'GB': 22, // United Kingdom
      'DE': 22, // Germany
      'FR': 27, // France
      'ES': 24, // Spain
      'IT': 27 // Italy
    };

    var countryCode = 'DE'; // Change this to the desired country code
    var ibanLength = countryCodes[countryCode];
    
    if (!ibanLength) {
      console.log('Invalid country code');
      return '';
    }
    
    var iban = countryCode;

    // Generate random digits for the IBAN
    for (var i = 0; i < ibanLength - 2; i++) {
      iban += Math.floor(Math.random() * 10);
    }

    return iban;
  }

  generatePurpose(){
    
    let purposes = [
      "Rechnung %D3",
      "Miete %MONTH %YEAR",
      "Strom %D6",
      "Gehalt %MONTH %YEAR",
      "Abbuchung %D5",
      "Rechnungsnummer %D4",
      "Vielen Dank für Ihren Auftrag %D5",
      "Auftrag %D4",
      "Auftragsnummer %D4",
      "AN %D4"
    ];

    let purpose = purposes[Math.floor(Math.random() * purposes.length)];

    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    purpose = purpose.replace("%MONTH", month.toString());
    purpose = purpose.replace("%YEAR", year.toString());
    
    for(let i = 1; i <= 9; i++){
      let replaceString = "%D" + i;
      let replaceValue = Math.floor(Math.random() * 10);
      purpose = purpose.replace(replaceString, replaceValue.toString());
    }
    return purpose;
  }

  submitTestData() {

    this.loading = true;

    // send to integration
    if (this.integration && this.integration.id) {
      this.integrationService.sendTestData(this.integration.id, this.transactions).then(() => {
        this.loading = false;
        this.toastController.create({
          message: this.translation.get('TEST_DATA_SENT_TO') + (this.integration.name ? ' ' + this.integration.name : ' Integration'),
          duration: 2000,
          position: 'top',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        }).then((toast) => {
          toast.present();
        });
        this.loading = false;

      }).catch((error) => {
        this.loading = false;
        if (error.error) error = error.error;
        this.toastController.create({
          message: (error.message ? error.message : error),
          duration: 5000,
          position: 'top',
          cssClass: 'toast-error',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        }).then((toast) => {
          toast.present();
        });

      });
    } else if(this.webhook && this.webhook.id) { // to a webhook
     
      this.webhookService.sendTestTransactionToWebhook(this.webhook.id, this.transactions).then(() => {
        this.loading = false;
        this.toastController.create({
          message: this.translation.get('TEST_DATA_SENT_TO') + (this.webhook.name ? ' ' + this.webhook.name : ' Webhook'),
          duration: 2000,
          position: 'top',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        }).then((toast) => {
          toast.present();
        });
      }).catch((error) => {
        this.loading = false;
        if (error.error) error = error.error;
        this.toastController.create({
          message: (error.message ? error.message : error),
          duration: 5000,
          position: 'top',
          cssClass: 'toast-error',
          buttons: [
            {
              text: 'OK',
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        }).then((toast) => {
          toast.present();
        });
      });

    } else {
      this.loading = false;
    this.toastController.create({
      message: "No integration or webhook selected",
      duration: 5000,
      position: 'top',
      cssClass: 'toast-error',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    }).then((toast) => {
      toast.present();
    });
  }
  }
}
