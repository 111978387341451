import { Injectable } from "@angular/core";
import { AnimationController } from "@ionic/angular";
@Injectable({
    providedIn: 'root'
})
export class ModalSideAnimation {
    
    enter: any;
    leave: any;

    constructor(public animationCtrl: AnimationController) {

        this.enter = (baseEl: HTMLElement) => {
            if(!baseEl) return;
    
            const root = baseEl.shadowRoot;
    
            if(!root) return;
    
            const backdropAnimation = this.animationCtrl
                .create()
                .addElement(root.querySelector('ion-backdrop')!)
                .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    
            const wrapperAnimation = this.animationCtrl
                .create()
                .addElement(root.querySelector('.modal-wrapper')!)
                .fromTo('opacity', 1, 1)
                .fromTo('transform', 'translateX(100%)', 'translateX(0)');
    
            return this.animationCtrl
                .create()
                .addElement(baseEl)
                .easing('cubic-bezier(0.32,0.72,0,1)')
                .duration(380)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        };
    
        this.leave = (baseEl: HTMLElement) => {
            if(!baseEl) return;
    
            const root = baseEl.shadowRoot;
    
            if(!root) return;
    
            const backdropAnimation = this.animationCtrl
                .create()
                .addElement(root.querySelector('ion-backdrop')!)
                .fromTo('opacity','var(--backdrop-opacity)', '0.01');
    
            const wrapperAnimation = this.animationCtrl
                .create()
                .addElement(root.querySelector('.modal-wrapper')!)
                .fromTo('opacity', 1, 1)
                .fromTo('transform', 'translateX(0)', 'translateX(100%)');
    
            return this.animationCtrl
                .create()
                .addElement(baseEl)
                .easing('cubic-bezier(0.32,0.72,0,1)')
                .duration(380)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        };

    }
}