import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AuthService } from '../../providers/auth-service/auth-service';
import { PilotService } from 'src/app/providers/pilot-service/pilot-service';

@Component({
  selector: 'app-pilot-popover',
  templateUrl: './pilot-popover.component.html',
  styleUrls: ['./pilot-popover.component.scss'],
})
export class PilotPopoverComponent implements OnInit {

  _message: string = "";
  get message() {
    return this._message;
  }
  set message(message: string) {
    this._message = message;
  }

  constructor(private navCtrl: NavController, private popoverController: PopoverController, public auth: AuthService, public pilotService: PilotService) { }

  ngOnInit() {}

  openFullPilot() {
    this.close({
      bigPilot: true
    });
    this.pilotService.openPilot();
  }

  async sendMessage() {

    this.close({
      bigPilot: true
    });
    await this.pilotService.openPilot();
    this.pilotService.sendMessage(this._message, this.pilotService.getReferenceTransaction());
  }


  close(data: any = null) {
    this.popoverController.dismiss(data);
  }
}
