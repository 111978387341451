import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

// Set options
marked.use({
  pedantic: false,
  gfm: true,
  breaks: true
});

@Pipe({
  name: 'md'
})
export class MdPipe implements PipeTransform {

  transform(value: string): string {
    if (value && value.length > 0) {

      var href_regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gm;

      // @ts-ignore
      return marked.parse(value)
      // @ts-ignore
      .replace(href_regex, '<a target="_blank" href="$2"')
      .replace(/(\r\n|\n|\r|\\n|\\r)/gm, "<br>");
    }
    return value;
  }

}
