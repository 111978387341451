import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './providers/auth-service/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },{
    path: 'signup',
    redirectTo: 'login',
    data: {
      mode: 'signup'
    },
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account-actions/account-actions.module').then( m => m.AccountActionsPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bank-accounts',
    loadChildren: () => import('./pages/bank-accounts/bank-accounts.module').then( m => m.BankAccountsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/bank-accounts',
    loadChildren: () => import('./pages/bank-accounts/bank-accounts.module').then( m => m.BankAccountsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./pages/upgrade/upgrade.module').then( m => m.UpgradePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/upgrade',
    loadChildren: () => import('./pages/upgrade/upgrade.module').then( m => m.UpgradePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'automations',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'automations/:tab',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'automations/:tab/:tabObj',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/automations',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/automations/:tab',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/automations/:tab/:tabObj',
    loadChildren: () => import('./pages/automations/automations.module').then( m => m.AutomationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'operations',
    loadChildren: () => import('./pages/operations/operations.module').then( m => m.OperationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/operations',
    loadChildren: () => import('./pages/operations/operations.module').then( m => m.OperationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/operations/:integrationId',
    loadChildren: () => import('./pages/operations/operations.module').then( m => m.OperationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/quick-connect',
    loadChildren: () => import('./pages/fast-connect/fast-connect.module').then( m => m.FastConnectPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/quick-sync',
    loadChildren: () => import('./pages/quick-sync/quick-sync.module').then( m => m.QuickSyncPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':projectId/quick-sync/:integrationId',
    loadChildren: () => import('./pages/quick-sync/quick-sync.module').then( m => m.QuickSyncPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':projectId/setup',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/profile-page/profile-page.module').then( m => m.ProfilePagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invites/:projectId',
    loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule),
    canActivate: [AuthGuard]
  },  
  {
    path: 'invites/:projectId/:inviteCode',
    loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invites/:projectId/:inviteCode/accept',
    loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ooops',
    loadChildren: () => import('./pages/something-went-wrong/something-went-wrong.module').then( m => m.SomethingWentWrongPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
