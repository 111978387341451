import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Account } from 'src/app/models/Account';
import { Chat } from 'src/app/models/Chat';
import { ChatMessage } from 'src/app/models/ChatMessage';
import { Transaction } from 'src/app/models/Transaction';
import { PilotService } from 'src/app/providers/pilot-service/pilot-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { TransactionService } from 'src/app/providers/transaction-service/transaction-service';

@Component({
  selector: 'app-pilot-page',
  templateUrl: './pilot-page.page.html',
  styleUrls: ['./pilot-page.page.scss'],
})
export class PilotPagePage implements OnInit {

  _transactions: Transaction[] = [];
  @Input() set transactions(transactions: Transaction[]) {
    this._transactions = transactions;
  }
  get transactions() {
    return this._transactions;
  }

  _filter: string = '';
  @Input() set filter(filter: string) {
    this._filter = filter;
  }
  get filter() {
    return this._filter;
  }

  _accounts: Account[] = [];
  @Input() set accounts(accounts: Account[]) {
    this._accounts = accounts;
  }
  get accounts() {
    return this._accounts;
  }

  _message: string = "";
  get message() {
    return this._message;
  }
  set message(message: string) {
    this._message = message;
  }


  _chat: Chat;
  get chat() {
    return this._chat;
  }


  constructor(private modalCtrl: ModalController,
    private projectService: ProjectService,
    private translate: TranslateService,
    private transactionService: TransactionService,
    private pilotService: PilotService
  ) { }

  ngOnInit() {

    this.transactionService.getTransactions(this.projectService.getCurrentProjectId(), {page: 1, perPage: 7}).then((transactions) => {
      this._transactions = transactions.transactions;
    });

    this.pilotService.initiateChat().then((chat) => {
      this._chat = chat;
    });
  }

  sendMessage() {
    this.pilotService.sendMessage(this._message);
    this._message = "";
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
