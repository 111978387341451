import { Injectable, Inject } from '@angular/core';
import { InstitutionService } from '../institution-service/institution-service';
import { AuthService } from '../auth-service/auth-service';
import { PilotService } from '../pilot-service/pilot-service';

export interface Shortcut {
  name: string;
  description: string;
  action: () => void;
  keys: string[];
}

@Injectable({
    providedIn: 'root'
})
export class ShortcutService {

  private shortcuts: Shortcut[] = [];

  private lastTwoKeys: string[] = [];
  
  private mousePosition: { x: number, y: number } = { x: 0, y: 0 };

  constructor(
    private institutionService: InstitutionService,
    private pilotService: PilotService,
    private authService: AuthService
  ){
    this.registerMousePositionListener();
    this.createDefaultShortcuts();
    this.registerShortcuts();
  }

  public getMousePosition(): { x: number, y: number }{
    return this.mousePosition;
  }

  public registerMousePositionListener(){
    document.addEventListener('mousemove', (event) => {
      this.mousePosition.x = event.clientX;
      this.mousePosition.y = event.clientY;
    });
  }

  public createDefaultShortcuts(){
    // control + b is open bank account modal
    this.shortcuts.push({
      name: 'SHORTCUT_OPEN_BANK_ACCOUNT_MODAL',
      description: 'Open bank account modal',
      action: () => {
        // only open the modal if the user is logged in
        if(!this.authService.isLoggedIn()) return;

        // only if no modal is open (check if "bank-connect-modal" class  on a ion-modal element exists)
        if(document.querySelector('.bank-connect-modal')) return;

        // not on quick pages (class accent-page)
        if(document.querySelector('.accent-page')) return;

        this.institutionService.openConnectBankAccountModal().then((result: any) => {
          if(result && result.state == "success") {
          }
        });
      },
      keys: ['control', 'b']
    });

    // control + k is opening the quick access bar

    this.shortcuts.push({
      name: 'SHORTCUT_MINI_PILOT',
      description: 'Open Mini Pilot',
      action: () => {
        // only open the modal if the user is logged in
        if(!this.authService.isLoggedIn()) return;

        // only if no pilot is open
        if(document.querySelector('ion-popover.pilot-popover')) return;
        
        // fake a mouse click at the current cursor position
        const mouseEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          clientX: this.mousePosition.x,
          clientY: this.mousePosition.y
        });
        document.body.dispatchEvent(mouseEvent);

        // reset refenence of pilot service
        this.pilotService.resetReference();

        if(!this.pilotService.getReferenceTransaction()){
          this.pilotService.openPilot(mouseEvent);
        } else {
          // open the mini pilot
          this.pilotService.openMiniPilot(mouseEvent, {});
        }
      },
      keys: ['control', 'k']
    });

  }

  public registerShortcuts(){
    // listen all keydown events and check if the shortcut is pressed
    document.addEventListener('keydown', (event) => {

      let actualKey = event.key;
      if(actualKey == "Meta") actualKey = "control";
      
      this.lastTwoKeys.push(actualKey);
      if(this.lastTwoKeys.length > 2){
        this.lastTwoKeys.shift();
      }

      // look for a shortcut that matches the last two keys
      const shortcut = this.shortcuts.find(shortcut => shortcut.keys[0] == this.lastTwoKeys[0] && shortcut.keys[1] == actualKey);
      if(shortcut){
        shortcut.action();
        event.preventDefault();
      }
     

    });
  }

  public triggerShortcut(keys: string[]){
    var shortcut = this.shortcuts.find(shortcut => shortcut.keys[0] == keys[0] && shortcut.keys[1] == keys[1]);
    if(!shortcut) shortcut = this.shortcuts.find(shortcut => shortcut.name == keys[0]);
    if(!shortcut) return;

    if(shortcut){
      shortcut.action();
    }
    return true;
  }


  public getShortcuts(): Shortcut[]{
    return this.shortcuts;
  }

  public getShortcut(name: string): Shortcut{
    return this.shortcuts.find((shortcut)=>{
      return shortcut.name === name;
    });
  }

}