import { Injectable, Inject } from '@angular/core';

import { AuthService } from './../auth-service/auth-service';
import { PilotPopoverComponent } from 'src/app/components/pilot-popover/pilot-popover.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { Transaction } from 'src/app/models/Transaction';
import { PilotPagePage } from 'src/app/pages/pilot-page/pilot-page.page';
import { ModalSideAnimation } from 'src/app/components/modal-side-animation';
import { Chat } from 'src/app/models/Chat';
import { ChatMessage } from 'src/app/models/ChatMessage';
import { ProjectService } from '../project-service/project-service';
import { HttpClient } from '@angular/common/http';
import { Account } from 'src/app/models/Account';

interface PredictionResponse {
  message: string;
  data: {
    transactions: Transaction[];
    accounts: Account[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class PilotService {

  private _referenceTransaction: Transaction;

  private _currentChat: Chat;
  private _chats: Chat[] = [];



  constructor(
    private auth: AuthService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private modalAnimation: ModalSideAnimation,
    private http: HttpClient,
    private projectService: ProjectService
  ) {

  }


  /*
    Open Mini Pilot Popover
  */

  public async openMiniPilot(ev: MouseEvent, opts: any = {}) {

    let webformId = opts.webformId || "";

    if (!ev) {
      const mouseEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        clientX: window.innerWidth / 2,
        clientY: (window.innerHeight / 2) - 40
      });
      document.body.dispatchEvent(mouseEvent);
    }

    this.createPilotRipple(ev.clientX - 40, ev.clientY - 40);

    const popover = await this.popoverController.create({
      component: PilotPopoverComponent,
      cssClass: 'pilot-popover',
      mode: "ios",
      translucent: true,
      showBackdrop: false,
      event: ev,
      reference: "event",
      componentProps: {

      }
    });
    await popover.present();

    popover.onWillDismiss().then((data) => {
      this.removePilotRipple();
    });

    return new Promise((resolve, reject) => {
      popover.onDidDismiss().then((result) => {
        if (opts.onDismiss) opts.onDismiss();
        if (result && result.data && result.data.success) {
          return resolve(result.data);
        } else {
          return reject(result);
        }
      });
    });
  }

  /*
    Open Pilot Modal
  */

  public async openPilot(opts: any = {}) {

    const modal = await this.modalController.create({
      component: PilotPagePage,
      cssClass: 'pilot-modal right-side-modal',
      showBackdrop: false,
      enterAnimation: this.modalAnimation.enter,
      leaveAnimation: this.modalAnimation.leave,
      componentProps: {

      }
    });
    await modal.present();

  }

  /*
    Send Message to Chat
  */
  public async sendMessage(message: string, context: any = null): Promise<ChatMessage> {
    return new Promise(async (resolve, reject) => {

      if (!this._currentChat) {
        this._currentChat = await this.initiateChat();
      }

      let userMessage = new ChatMessage();
      userMessage.type = 'user';
      userMessage.text = message;
      userMessage.createdAt = new Date();
      userMessage.data = {};

      if(context) {
        console.log(context,"CONTEXT");
        userMessage.data.transactions = Array.isArray(context) ? context : [context];
      }
      
      console.log(userMessage,"USER MESSAGE");

      this._currentChat.messages.push(userMessage);


      this.invokeAgent(message, context).then((res) => {

        let aiMessage = new ChatMessage();
        aiMessage.type = 'ai';
        // @ts-ignore
        aiMessage.text = res.message;
        aiMessage.createdAt = new Date();

        aiMessage.data = {
          transactions: res?.data?.transactions || [],
          accounts: res?.data?.accounts || null,
        };

        this._currentChat.messages.push(aiMessage);

      }, (err) => {
        return reject(err);
      });

      return resolve(userMessage);
    });
  }

  private invokeAgent(message: string, context: any = null): Promise<PredictionResponse> {
    return new Promise((resolve, reject) => {

      let url = 'http://localhost:3000/v1/'+this.projectService.getCurrentProject().id+'/ai/prediction';

      // make a post request to the agent
      this.http.post(url, { message: message, context: context }).subscribe((res) => {
        return resolve(res as PredictionResponse);
      }, (err) => {
        return reject(err);
      });
    });
  }

  /*
    Inject Message into Chat
  */
  public injectMessage(message: ChatMessage, index?: number) {
    if(typeof index === 'number') {
      this._currentChat.messages.splice(index, 0, message);
    } else {
      this._currentChat.messages.push(message);
    }
    return message;
  }

  public getMessages(): ChatMessage[] {
    return this._currentChat.messages;
  }

  /*
    Initiate a new Chat
  */
  public async initiateChat(): Promise<Chat> {
    return new Promise((resolve, reject) => {
      this._currentChat = new Chat();
      this._currentChat.id = '1';
      this._currentChat.userId = this.auth.currentUser.uid;
      this._currentChat.createdAt = new Date();
      this._currentChat.lastUsedAt = new Date();
      this._currentChat.messages = [];
      return resolve(this._currentChat);
    });
  }
  public getCurrentChat(): Chat {
    return this._currentChat;
  }

  /*
    Reference Handling
  */

  public setReferenceTransaction(transaction: Transaction) {
    this._referenceTransaction = transaction;
  }

  public getReferenceTransaction(): Transaction {
    return this._referenceTransaction;
  }

  public setReference(object: any, type: 'transaction' | 'project' | 'category' | 'bank' | 'account') {
    this._referenceTransaction = null;
    if (type == 'transaction') this._referenceTransaction = object as Transaction;
  }

  public resetReference() {
    this._referenceTransaction = null;
  }

  public createPilotRipple(x: number, y: number) {
    // create the ai effect
    const ripple = document.createElement("div");
    // it has 3 child divs
    const child1 = document.createElement("div");
    const child2 = document.createElement("div");
    const child3 = document.createElement("div");

    // they have random positions between -15 and 15
    child1.style.top = `${-15 + (Math.random() * 30 - 15)}px`;
    child1.style.left = `${-15 + (Math.random() * 30 - 15)}px`;
    child2.style.top = `${+15 + (Math.random() * 30 - 15)}px`;
    child2.style.left = `${+15 + (Math.random() * 30 - 15)}px`;
    child3.style.top = `${-15 + (Math.random() * 40 - 20)}px`;
    child3.style.left = `${0 + (Math.random() * 40 - 20)}px`;

    // they have random sizes between 35 and 70
    child1.style.width = `${Math.random() * 35 + 35}px`;
    child1.style.height = `${Math.random() * 35 + 35}px`;
    child2.style.width = `${Math.random() * 35 + 35}px`;
    child2.style.height = `${Math.random() * 35 + 35}px`;
    child3.style.width = `${Math.random() * 35 + 35}px`;
    child3.style.height = `${Math.random() * 35 + 35}px`;

    ripple.appendChild(child1);
    ripple.appendChild(child2);
    ripple.appendChild(child3);

    ripple.className = "pilot-ripple";
    document.querySelector('ion-app').appendChild(ripple);

    ripple.classList.add("pop-in");
    // set pop-life after 400ms
    setTimeout(() => {
      ripple.classList.remove("pop-in");
      ripple.classList.add("pop-life");
    }, 400);

    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;

    //ripple.onanimationend = () => document.body.removeChild(ripple);
  }

  public removePilotRipple() {
    if (document.querySelector('.pilot-ripple')) {
      // add class pop-out
      document.querySelector('.pilot-ripple').classList.add("pop-out");
      // remove the ripple after the animation ends
      document.querySelector('.pilot-ripple').addEventListener('animationend', () => {
        if (document.querySelector('.pilot-ripple')) document.querySelector('ion-app').removeChild(document.querySelector('.pilot-ripple'));
      });
    }
  }

}
